import React from 'react'

import Layout from '../layouts/layout'
import SEO from '../components/seo'

import error from '../images/draws/error404.svg'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="hero-section2 ">
      <img src={error} className="hero-image-404" />
      <div className="hero-texts">
        <p className="hero-header">
          ERROR 404
            </p>
        <div className="dash" />
        <p>
        Ooops, it seems that you are lost..
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
