import React from 'react'
import Link from 'gatsby-link'
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'
import GatsbyConfig from '../../gatsby-config'
// import {HashLink} from 'react-router-hash-link'

const Header = ({ siteTitle }) => (
  <div className="nav-bar">
    <Helmet
      title={GatsbyConfig.siteMetadata.title}
      meta={[
        { name: 'description', content: 'BiasImpact' },
        {
          name: 'keywords',
          content: 'News, Media, Fake News, Natural Language Processing',
        },
      ]}
      link={[{ rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }]}
    />

    <div className="container">
      <div className="nav-items">
        <span className="brand">{siteTitle}</span>
        <ul>
          <li>
            <Link to="/" exact={true} activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            {/* <HashLink to="/#contact">Contact</HashLink> */}
          </li>
        </ul>
      </div>
    </div>
  </div>
)



export default Header
