import React from 'react'
import PropTypes from 'prop-types'

import Header from '../components/header'
import Footer from '../components/footer'

import '../styles/main.scss'

const Layout = ({ children }) => (
  <div id="flexContainer">
    <Header siteTitle="BiasImpact" />
    <main>
      {children}
    </main>
    <Footer siteTitle="BiasImpact" />
  </div>

);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
