import React from 'react';

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="info-area">
        <div className="column">
          <a href="https://github.com/datafornews">GitHub</a>
        </div>
        <div className="column">
          <a href="https://datafor.news">Data For News</a>
        </div>
        <div className="column">
          <strong>Contact</strong>
          contact&lt;at&gt;biasimpact.org
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <p>BiasImpact © {new Date().getFullYear()}</p>
    </div>
  </footer>
)

export default Footer
